import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuickFilterService {

  public worker?: Worker;

  constructor() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('./large-list.worker', import.meta.url));
    } else {
      console.error('Web Workers are not supported in this environment.');
    }
  }

  getData(array: any[], filterCriteria: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (!this.worker) {
        reject('Web Worker is not available.');
        return;
      }

      if(!filterCriteria) resolve(array);

      this.worker.onmessage = ({ data }) => {
        resolve(data);
      };
      this.worker.onerror = (error) => reject(error);

      this.worker.postMessage({ array, key: filterCriteria });
    });
  }
}
